import React from "react";

const menus2 = (data) => {
    var component = [];
    var component2 = [];
    var component3 = [];
    for (let index = 0; index < data.data.length; index++) {
        var tamanio = data.data[index].hijos;
        if (data.data[index].titulo !== "BLOG " && data.data[index].titulo !== "PROMOCIONES") {
            for (let index2 = 0; index2 < data.data[index].hijos.length; index2++) {
                var url = data.data[index].hijos[index2].link.link_path
                component2.push(<li class="text-start justify-content-start leaf"><a
                    href={url.includes('https') === true ? url : '/' + url}>{data.data[index].hijos[index2].link.link_title}</a>
                </li>)
            }
            component.push(<li className={'expanded'}><span class="nolink" tabindex="0">{data.data[index].titulo}</span>
                <ul class="menu" style={{paddingLeft: '0px'}}>{component2}</ul>
            </li>);
            component2 = [];
        }
    }
    return component;
}

const MenuFooter = (data) => {
    return (
        <section
            class="block block-menu d-lg-block d-none block-menu-menu-foooter-menu-2019 block-menu-menu-foooter-menu-2019">
            <ul class="menu">
                {menus2(data)}
            </ul>
        </section>
    );
};

export default MenuFooter;