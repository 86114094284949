import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { wrapper } from 'axios-cookiejar-support';
import * as app from "../fetch/fetchApi";
import BannerMensaje from "./banner_mensaje";
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import $ from 'jquery';
const axios = require('axios');
const jar = app.jar;
const client = wrapper(axios.create({ jar }));
const menus = (data) => {
   data = Object.values(data)
   var component = [];
   var component2 = [];
   var component3 = [];

   for (let index = 0; index < data.length; index++) {
      if (data[index].hijos) {
         for (let index2 = 0; index2 < data[index].hijos.length; index2++) {
            if (data[index].hijos[index2].hijos) {
               for (let index3 = 0; index3 < data[index].hijos[index2].hijos.length; index3++) {
                  var url = data[index].hijos[index2].hijos[index3].link.link_path;
                  var id = data[index].hijos[index2].hijos[index3].link.href
                  component3.push(<li class="text-start justify-content-start leaf">
                     <a href={url.includes('https') === true ? url : '/' + url}>{data[index].hijos[index2].hijos[index3].link.link_title}</a>
                  </li>)
               }
               component2.push(<li name="prueba" class=" has-dropdown  not-click text-start justify-content-start third-level"> <LazyLoadComponent> <label>{data[index].hijos[index2].link.link_title}</label><LazyLoadImage src={app.url + "/sites/all/themes/tickets/2019/svg/menu-active.svg"} /><ul class="dropdown">{component3}</ul></LazyLoadComponent></li>)
            } else {
               var url3 = data[index].hijos[index2].link.link_path
               component2.push(<li name="prueba" class="text-start justify-content-start leaf"> <a href={url3.includes('https') === true ? data[index].hijos[index2].link.link_path : '/' + data[index].hijos[index2].link.link_path}>{data[index].hijos[index2].link.link_title}</a></li>)
            }
            component3 = [];
         }
         component.push(<li name="prueba" class="has-dropdown not-click me-3" > <LazyLoadComponent> <label>{data[index].titulo}</label><ul class="dropdown">{component2}</ul></LazyLoadComponent></li>);
      } else {
         var url2 = data[index].path
         component.push(<li name="prueba" class="leaf me-3"> <a href={url2.includes('https') === true ? data[index].path : '/' + data[index].path}>{data[index].titulo}</a></li>);
      }
      component2 = [];
   }
   return component;
}

const BannerMobile = ({ data }) => {
   const isBrowser = typeof window !== "undefined"
   var cantidad = 0;
   const [datos, setData] = useState(0)
   useEffect(() => {
      peticion()
   }, [])

   async function peticion() {
      await client.get(app.url + '/node-api/m22_get_cantidad/1', {
         withCredentials: true,
         headers: {
            crossDomain: true,
            'Content-Type': 'application/json'
         },
      })
         .then(function (response) {
            var num = response.data === null ? 0 : response.data
            setData(num)
            localStorage.setItem('cantidadProds', num)
         })
         .catch(function (error) {
            console.log(error)
         })

   }
   if (isBrowser) {
      window.addEventListener('storage', () => {
         setTimeout(() => {
            setData(localStorage.getItem('cantidadProds'))
         }, 6000);
      });

   }
   return (
      <header id="menuNav" role="banner" class="l-header" name='normal'>
         <BannerMensaje />

         <div class={'contain-to-grid'}>
            <nav class="top-bar" data-topbar="" data-options="">
               <ul class="title-area" style={{ width: 'unset' }}>
                  <li class="name">
                     <h1><a href="/" rel="home" title="Las Estacas Inicio">Las Estacas</a></h1>
                  </li>
                  <li class="logo"><a href="/" rel="home" title="Las Estacas Inicio" > <LazyLoadComponent> <LazyLoadImage class="logo" src={app.url + "/sites/all/themes/tickets/img/Logo.svg"} alt="Las Estacas logo" title="Las Estacas Inicio" /> </LazyLoadComponent></a></li>
               </ul >
               <section class="top-bar-section">
                  <ul id="main-menu" class="main-nav left">
                     {menus(data)}
                  </ul>
                  <ul id="secondary-menu" class="secondary link-list right oficial">
                     <li class="last leaf cart" name="cart"><Link to={app.url + "/cart"} name="cart" class="cart">cart{datos > 0 ? <p id="prod-qty" class="circle number">{datos}</p> : ''}</Link></li>
                     <li class="toggle-topbar menu-icon notopen d-lg-none d-block"> <a href="#"> <span class="button-theme">MENÚ</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </a></li>
                  </ul>
               </section>
            </nav>
         </div>
      </header>
   );
};

export default BannerMobile;
