import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import $ from "jquery"
import {wrapper} from 'axios-cookiejar-support';
import BannerMensaje from "./banner_mensaje";
import * as app from "../fetch/fetchApi";
import {LazyLoadComponent, LazyLoadImage} from 'react-lazy-load-image-component';

const axios = require('axios');
const jar = app.jar;
const client = wrapper(axios.create({jar}));
const isBrowser = typeof window !== "undefined"

const menus = (data) => {
    data = Object.values(data)
    var component = [];
    var component2 = [];
    var component3 = [];
    for (let index = 0; index < data.length; index++) {
        if (data[index].hijos) {
            for (let index2 = 0; index2 < data[index].hijos.length; index2++) {
                if (data[index].hijos[index2].hijos) {
                    for (let index3 = 0; index3 < data[index].hijos[index2].hijos.length; index3++) {
                        var url = data[index].hijos[index2].hijos[index3].link.link_path;
                        var id = data[index].hijos[index2].hijos[index3].link.href
                        component3.push(<li class="text-start justify-content-start sty leaf d-block">
                            <a href={url.includes('https') === true ? url : '/' + url}>{data[index].hijos[index2].hijos[index3].link.link_title}</a>
                        </li>)
                    }
                    component2.push(<li name="prueba"
                                        class={" has-dropdown mt-3  not-click text-start justify-content-start third-level d-block mostrarPadre" + index2}>
                        <LazyLoadComponent> <label
                            className={"class" + index2}>{data[index].hijos[index2].link.link_title}</label>
                            <ul style={{height: '100%'}}
                                class={"dropdown hijos3  sty mostrarse" + index2}>{component3}</ul>
                        </LazyLoadComponent></li>)
                } else {
                    var url3 = data[index].hijos[index2].link.link_path
                    component2.push(<li name="prueba" class="text-start mt-3 justify-content-start leaf"><a
                        href={url3.includes('https') === true ? data[index].hijos[index2].link.link_path : '/' + data[index].hijos[index2].link.link_path}>{data[index].hijos[index2].link.link_title}</a>
                    </li>)
                }
                component3 = [];
            }
            component.push(<li name="prueba" class={"has-dropdown  not-click me-3 clic" + index}><LazyLoadComponent>
                <label className={"clasPadre" + index}>{data[index].titulo}</label>
                <ul class={"dropdown heig" + index + "  prueba" + index}>{component2}</ul>
            </LazyLoadComponent></li>);
        } else {
            var url2 = data[index].path
            component.push(<li name="prueba" class="leaf  me-3"><a
                href={url2.includes('https') === true ? data[index].path : '/' + data[index].path}>{data[index].titulo}</a>
            </li>);
        }
        component2 = [];
    }
    return component;
}
var clic = false
var count = 0;
var clic2 = false
var count2 = 0;
var count3 = 0;
var clic3 = false
var elemento = undefined

if (isBrowser) {
    $(document).click(function (event) {
        var text = $(event.target);
        if (text[0].className.includes("clasPadre")) {
            var id = text[0].className.replace('clasPadre', '')
            id = parseInt(id)
            $('.heig' + id).css("height", "auto")
            if (clic2 === true) {
                $('.heig' + id).css("height", "0")
            }
            if (clic2 === false) {
            }
            count2 = count2 + 1;
            if (count2 % 2 === 0) {
                clic2 = false
            } else {
                clic2 = true
            }
            if (clic === true) {
                $('.prueba' + id).css("padding-bottom", "unset")
                $('.heig' + id).css("height", "0px")
            }
            if (clic === false) {
                $('.heig' + id).css("height", "1000px")
                $('.prueba' + id).css("padding-bottom", "1000px")
            }
            count = count + 1;
            if (count % 2 === 0) {
                clic = false
            } else {
                clic = true
            }
        }

        if (text[0].className.includes("class")) {
            var id = text[0].className.replace('class', '')
            id = parseInt(id)
            if (clic3 === true) {
                $('.mostrarse' + id).css("display", "none")
                $('.mostrarPadre' + id).css("padding-bottom", "0px")
            }
            if (clic3 === false) {
                $('.mostrarPadre' + id).css("padding-bottom", "1000px")
                $('.mostrarse' + id).css("display", "contents")
            }
            count3 = count3 + 1;
            if (count3 % 2 === 0) {
                clic3 = false
            } else {
                clic3 = true
            }

        }
    });
}

function addStyles(index) {
    setTimeout(() => {
        $('.clic' + index).unbind('click').click(function () {
            $('.heig' + index).css("height", "auto")
            if (clic2 === true) {
                $('.heig' + index).css("height", "0")
            }
            if (clic2 === false) {
            }
            count2 = count2 + 1;
            if (count2 % 2 === 0) {
                clic2 = false
            } else {
                clic2 = true
            }
        })

        $('.prueba' + index).unbind('click').click(function () {
            $('.hijos3').css("padding-bottom", "500px")
            if (clic === true) {
                $('.prueba' + index).css("padding-bottom", "unset")
                $('.heig' + index).css("height", "0px")
            }
            if (clic === false) {
                $('.heig' + index).css("height", "1000px")
                $('.prueba' + index).css("padding-bottom", "1000px")
            }
            count = count + 1;
            if (count % 2 === 0) {
                clic = false
            } else {
                clic = true
            }
        });
    }, 1000);
}

function quitBottom(index) {
}

setTimeout(() => {
    if (isBrowser) {
        $('.sty').attr("style", " ");
        $('.not-click').click(function () {
        });
        $('.fixed-container').click(function () {
            $('.prueba0').css("padding-bottom", "0px")
        })
    }
}, 2000);

const Banner = ({data}) => {
    var cantidad = 0;
    const [datos, setData] = useState(null)

    async function peticion() {
        await client.get(app.url + '/node-api/m22_get_cantidad/1', {
            withCredentials: true,
            headers: {
                crossDomain: true,
                'Content-Type': 'application/json'
            },
        })
            .then(function (response) {
                var num = response.data === null ? 0 : response.data
                setData(num)
                localStorage.setItem('cantidadProds', num)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    useEffect(() => {
        peticion()
    }, [])

    if (isBrowser) {
        window.addEventListener('storage', () => {
            setTimeout(() => {
                setData(localStorage.getItem('cantidadProds'))
                $('#secondary-menu').appendTo('header .title-area').insertAfter('header nav >.title-area > .logo');
            }, 8000);
        });
    }
    return (
        <header id="menuNav" role="banner" class="l-header" name="mobile">
            <BannerMensaje/>
            <div class={'contain-to-grid'}>
                <nav class="top-bar" data-topbar="" data-options="">
                    <ul id="agregar" class="title-area">
                        <li class="name">
                            {datos !== null ?
                                <h1><a href="/" rel="home" title="Las Estacas Inicio">Las Estacas</a></h1> : ''}
                        </li>
                        {datos !== null ?
                            <li class="logo"><a href="/" rel="home" title="Las Estacas Inicio"> <LazyLoadComponent>
                                <LazyLoadImage class="logo" src={app.url + "/sites/all/themes/tickets/img/Logo.svg"}
                                               alt="Las Estacas logo"
                                               title="Las Estacas Inicio"/></LazyLoadComponent></a></li> : ''}
                    </ul>
                    <section class="top-bar-section">
                        <ul id="main-menu" class="main-nav left tab menuWid" style={{width: '100%'}}>
                            {menus(data)}
                        </ul>
                        <ul id="secondary-menu" class="secondary link-list right">
                            <li class="last leaf cart" name="cart"><Link to={app.url + "/cart"} name="cart"
                                                                         class="cart">cart{datos > 0 ?
                                <p id="prod-qty" class="circle number">{datos}</p> : ''}</Link></li>
                            <li class="toggle-topbar menu-icon notopen d-xl-none d-block"><a className="cerrar"
                                                                                             href="#"> <span
                                class="button-theme">MENÚ</span> <span class="icon-bar"></span> <span
                                class="icon-bar"></span> <span class="icon-bar"></span> </a></li>
                        </ul>
                    </section>
                </nav>
            </div>
        </header>
    );
};
export default Banner;
