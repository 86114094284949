import React from "react";
import FooterSocial from "./footer_social";

const Copyright = () => {
  return (
    <div>
    <div class="copyright"> 
    <span>Derechos reservados 2024&nbsp;</span>
    <span> | ©LAS ESTACAS </span>
    <span>| HECHO EN MÉXICO</span>
    </div>
    <FooterSocial />
    </div>
  );
};

export default Copyright;