import $ from 'jquery';

const isBrowser = typeof window !== "undefined"
if (isBrowser) {
$(document).ready(function() {
  setTimeout(() => {
    if ($('body').hasClass('front')) {
        $('#map-container').mousemove(function(e) {
            if ("ontouchstart" in document.documentElement) {} else {
                var imgClone = $(this).children('img').clone();
                if ($('#modalMap img').length < 1) {
                    imgClone.appendTo($(this).children('#modalMap'))
                }
                var widthScreen = $('#map-container').width();
                var heightContainer = $('#map-container').height();
                var blockYpos = $(this).offset().top;
                var xPos = e.pageX;
                var yPos = e.pageY - blockYpos;
                if ($(window).width() < 1360) {
                    $('#modalMap').css({
                        'top': yPos,
                        'left': xPos + 20,
                        'display': 'block'
                    });
                    $('#modalMap img').css({
                        'top': (yPos * -2) + 50,
                        'left': (xPos * -2) + 100,
                        'display': 'block'
                    });
                } else {
                    var gap = parseInt($('body main > div').css('padding-left'));
                    var scaleImgX = $(this).children('#modalMap').children('img').width();
                    var scaleImgY = $(this).children('#modalMap').children('img').height();
                    var containerX = $(this).width();
                    var containerY = $(this).height();
                    var relationX = (scaleImgX / containerX);
                    var relationY = (scaleImgY / containerY);
                    var relation = ((xPos - gap) * relationX);
                    $('#modalMap').css({
                        'top': yPos + 20,
                        'left': xPos + 20 - gap,
                        'display': 'block'
                    });
                    $('#modalMap img').css({
                        'top': (yPos * (relationX * -1)) + 50,
                        'left': (relation * -1),
                        'display': 'block'
                    });
                }
            }
        });
        $('#map-container').mouseout(function(e) {
            $('#modalMap').css('display', 'none');
        });

        function lightbox_sitio(sitio) {
            $.magnificPopup.open({
                items: {
                    src: '/mapa/' + sitio
                },
                type: 'iframe',
                srcAction: 'iframe_src'
            });
        }

        function hash_lightbox(hashtext) {
            if ($('#map-estacas').contents().find('.mapplic-layer a[href="' + window.location.hash + '"]').length == 1) {
                var sitio = $('#map-estacas').contents().find('.mapplic-layer a[href="' + window.location.hash + '"]').attr('data-path');
                lightbox_sitio(sitio)
            }
        }

        if ($(window).width() >= 768) {
            $('.block-block-5').html('<iframe id="map-estacas" src="/mapa/index.html" style="width: 100%; height: 670px; border: none; margin-left: auto; margin-right: auto;"></iframe>');
            $('body').on('tooltip', function() {
                $('#map-estacas').contents().find('a.mapplic-tooltip-title').on('click', function() {
                    lightbox_sitio($(this).attr('data-path'));
                })
            });

            if ($('body').hasClass('front')) {
                $('.block-block-5').show();
                $('body').on('mapLoaded', function() {
                    $('#map-estacas').contents().find('a.mapplic-pin').click(function() {
                        lightbox_sitio($(this).attr('data-path'));
                        window.location.hash = $(this).attr('data-location');
                    })
                    if (window.location.hash.length > 0) {
                        hash_lightbox(window.location.hash)
                    }
                    window.onhashchange = hash_lightbox;
                    $('#map-estacas').contents().find('.mapplic-map, .mapplic-clear-button').show();
                    $('#map-estacas').contents().find('.mapplic-map, .mapplic-clear-button').show();
                    if (window.location.hash.length > 0) {
                        $('#map-estacas').attr('src', $('#map-estacas').attr('src') + window.location.hash)
                    }
                })
            } else {
                $('.block-block-5').show();
                $('body').on('mapLoaded', function() {
                    $('#map-estacas').contents().find('.mapplic-map, .mapplic-clear-button').hide();
                    $('#map-estacas').contents().find('.mapplic-levels-select a').click(function() {
                        $('body').css('background-color', '#fff')
                        $('.main-container.container').remove();
                        $('#block-system-main').html('')
                        $('#block-system-main').css('min-height', '0px')
                        $('.block-block-5').css('margin-top', '0px');
                        $('body').scrollTop(0);
                        $('#map-estacas').contents().find('.mapplic-map, .mapplic-clear-button').show();
                        $('#map-estacas').contents().find('a.mapplic-pin').click(function() {
                            lightbox_sitio($(this).attr('data-path'));
                        })
                    });
                })
            }
        }
    }
  }, 2000);
});
}