import $ from 'jquery'; 

const isBrowser = typeof window !== "undefined"

if (isBrowser) {
  

$(document).ready(function($) {
	var pageOrders = $('body').hasClass('page-pedidos');
	var cart = $('body').hasClass('page-cart');
    if (pageOrders) {
        getGET();
    }
    if (cart) {
    	$('.long-text-trigger').on('click', function(event) {
    		var button = $(this);
    		button.toggleClass('open');
    		smallLetters(button);
    	});
    }
    $('.section-atracciones .row.l-main aside section .view-productos-sin-sitio-de-inter-s').parent('section').addClass('productos');
});

// Listener para cerrar el modal
$('.close-reveal-modal').on('click', function(event) {
    closeModal();
});



// Funcion para obtener los parametros
function getGET(){
	// capturamos la url
	var loc = document.location.href;
	// si existe el interrogante
	if(loc.indexOf('?')>0){
	    // cogemos la parte de la url que hay despues del interrogante
	    var getString = loc.split('?')[1];
	    //evaluamos si el parametro es el mismo 
	    if(getString === 'pass_reset=1') {
	    	//se lanza el modal para cambiar la contraseña
	        $('a[data-reveal-id="user-data"]').click();
	    }
	}
}

// Funcion para mostrar las letras chicas dependiendo del tamaño del dispositivo
function smallLetters(button){
	var screen = $(window).width();
	var hideText = button.parents('.data-product-cart').siblings('.oculto.long-text');
	// Si el ancho es mayor 768 calcula el alto y se lo asigna para crear una animacion de despliegue
	if (screen > 767) {
		if (button.hasClass('open')) {
			var heightText = hideText.children('.field-content').height() + 30;

			hideText.removeClass('oculto').css('height', heightText);
		}else {
			button.parents('.data-product-cart').siblings('.long-text').css('height', 0).addClass('oculto');
		}
		// De lo contrario, habre la informacion en un modal
	}else {
		hideText.clone().appendTo('.page-cart #addtocart .modal-content');
		$('<h2 class="title-2-sb">Letra chica</h2>').prependTo('.page-cart #addtocart .modal-content .long-text')
		var modal = $('#addtocart');
    	var bodyModal = modal.find('.modal-content');
		$('#addtocart').foundation('reveal','open');
		$('body > #addtocart').addClass('reveal-modal open').removeClass('close-reveal-modal');
	}
}
// Funcion para cerrar el modal en caso de que se abra
function closeModal(){
    $('.reveal-modal-bg').css('display', 'none');
    $('#addtocart').removeClass('reveal-modal open');
    $('#addtocart .modal-content').children().remove();
}
}