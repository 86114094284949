import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as app from "../fetch/fetchApi";


const FooterSocial = () => {
  const isBrowser = typeof window !== "undefined"


  function openWaze(params) {
    if (isBrowser) {
      window.open('https://waze.com/ul?ll=18.732481,-99.114636&amp;navigate=yes', '_blank');
    }
  }
  function openMaps(params) {
    if (isBrowser) {
      window.open('https://www.google.com/maps/dir/?op=Enviar&amp;api=1&amp;destination=Las+Estacas,+Morelos', '_blank');
    }
  }
  function openPhone(params) {
    if (isBrowser) {
      window.open("tel: 734 - 120 - 2916", "_blank");
    }
  }
  function openWhats(params) {
    if (isBrowser) {
      window.open("https://api.whatsapp.com/send?phone=527341142287", "_blank")
    }
  }

  return (
    <div class="row d-block d-sm-none" id="widget-contacto-footer">
      <div class="small-3 columns">
        <button onclick={() => openWaze()} type="button" name="waze" >
          <LazyLoadImage width="27" height="25" src={app.url + "/sites/all/themes/tickets/2019/svg/waze.svg"} alt="waze" />
        </button>
      </div>
      <div class="small-3 columns">
        <button onClick={() => openMaps()} type="button" name="maps">
          <LazyLoadImage width="27" height="25" src={app.url + "/sites/all/themes/tickets/2019/svg/maps.svg"} alt="google maps" />
        </button>
      </div>
      <div class="small-3 columns">
        <button onClick={() => openPhone()} type="button" name="celular">
          <LazyLoadImage width="27" height="25" src={app.url + "/sites/all/themes/tickets/2019/svg/phone-focused.svg"} alt="teléfono" />
        </button>
      </div>
      <div class="small-3 columns">
        <button onClick={() => openWhats()} type="button" name="whatsapp">
          <LazyLoadImage width="27" height="25" src={app.url + "/sites/all/themes/tickets/2019/svg/whatsapp.svg"} alt="whatsapp" />
        </button>
      </div>
    </div>
  );
};

export default FooterSocial;