import $ from 'jquery'; 

    
const isBrowser = typeof window !== "undefined"

if (isBrowser) {
  


  	$(document).ready(
  		function()
  		{
  			collapsible_animation($,call_mansory);
  		}
  	);
  


function collapsible_animation($,callback)
{
	$(".lista-contenidos-colapse .fieldset-legend  a").click(
		function(e)
		{
				
				if(!$(this).parents('.views-row').find(".fieldset-wrapper").hasClass('activo'))
				{
					$(".activo").addClass("inactivo");
					$(".activo").removeClass("activo");
					$(this).parents('.views-row').find(".fieldset-wrapper").addClass("activo");
					$(this).parents('.views-row').find(".fieldset-wrapper").removeClass("inactivo");
					$(this).parents('.fieldset-legend').css("background-image","url('../../../../sites/default/files/arrow-up.png')");
				}
				else
				{
					$(this).parents('.views-row').find(".fieldset-wrapper").removeClass("activo");	
					$(this).parents('.views-row').find(".fieldset-wrapper").addClass("inactivo");	
					$(this).parents('.fieldset-legend').css("background-image","url('../../../../sites/default/files/arrow-down.png')");

				}
				$(".inactivo").css("display","none");
				$(".activo").css("display","block").promise().done(
					function()
					{
						$(".inactivo").parent().find(".fieldset-legend").css("background-image","url('../../../../sites/default/files/arrow-down.png')");
						//call_mansory($);
					}
				);
			
		}
	);
}
function call_mansory($)
{
	//$('.lista-contenidos-colapse .view-content').masonry();
}
}