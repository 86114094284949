import React from "react";


import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';




const Redes = () => {
  //setTimeout(() => {
    //yall();

  return (
<section class="block block-block block-block-13 block-block-13 d-md-block">
    <div class="social-networks-footer">
        <ul className={'d-flex row justify-content-md-between justify-content-center '}>
            <li className={'col-3 col-sm-1 px-0 '}>
                <a href="https://instagram.com/lasestacas_oficial/" target="_blank">
                    <LazyLoadComponent >
                        <div class="instagram lazy">
                        <i></i>

                        </div>
                    </LazyLoadComponent>
                </a>
            </li>
            <li  className={'col-3 col-sm-1 mx-sm-2 px-0'}>
                <a href="https://www.facebook.com/lasestacas.parquenatural" target="_blank">
                <LazyLoadComponent >

                    <div class="facebook lazy">
                        <i></i>
                    </div>
                    </LazyLoadComponent>
                </a>
            </li>
            <li  className={'col-3 col-sm-1  px-0'}>
                <a href="https://twitter.com/LasEstacas_" target="_blank">
                <LazyLoadComponent >

                    <div class="twitter lazy">
                        <i></i>
                    </div>
                    </LazyLoadComponent>
                </a>
            </li>
            <li  className={'col-3 col-sm-1 mx-sm-2 px-0'}>
                <a href="https://www.youtube.com/channel/UCqT70kjt4o4BdSC6Msy6Iig" target="_blank">
                <LazyLoadComponent >

                    <div class="video lazy">
                        <i></i>
                    </div>
                    </LazyLoadComponent>
                </a>
            </li>
            <li  className={'col-3 col-sm-1 mt-3 mt-sm-0  px-0'}>
                <a href="https://ww w.tiktok.com/@lasestacas" target="_blank">
                <LazyLoadComponent >

                    <div class="tiktok lazy">
                        <i></i>
                    </div>
                    </LazyLoadComponent>
                </a>
            </li>
            <li  className={'col-3 col-sm-1 mx-sm-2 mt-3 mt-sm-0 px-0'}>
                <a href="mailto:informes@lasestacas.com" target="_blank">
                <LazyLoadComponent >

                    <div class="email lazy">
                        <i></i>
                    </div>
                    </LazyLoadComponent>
                </a>
            </li>
            <li  className={'col-3 col-sm-1 mt-3 mt-sm-0 px-0'}>
                <a href="https://open.spotify.com/user/at9t99jjqqy6w4bvwykkhqdus?si=bz442UM6QUa8kL8W366tLQ&utm_source=copy-link" target="_blank">
                <LazyLoadComponent >

                    <div class="spotify lazy">
                        <i></i>
                    </div>
                    </LazyLoadComponent>
                </a>
            </li>
        </ul>
    </div>
</section>
  );
};

export default Redes;